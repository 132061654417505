import React from 'react';

import ProtectedRoute from '$components/protectedRoute';
import DashboardLayout from '$components/layout/dashboard';
import UserNavigation from './navBar';

const UserLayout = props => {
  return (
    <ProtectedRoute>
      <DashboardLayout {...props} navBar={UserNavigation} />
    </ProtectedRoute>
  );
};

export default UserLayout;
