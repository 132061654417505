import React from "react";

import Heading from "$ui/heading";

import UserLayout from "$components/layout/user";
import EventsPanel from "$components/events/eventsPanel";
import ComplaintsTable from "$components/complaints/complaintsTable";

const MyComplaintsPage = () => {
  return (
    <UserLayout title="My Complaints">
      <section className="p-10 phone:px-5">
        <Heading variant="tertiary-left" className="mb-4">
          My Complaints
        </Heading>
        <ComplaintsTable />
      </section>

      {/* <section className='bg-white py-10 px-4'>
        <Heading variant='tertiary-left'>Upcoming events</Heading>
        <EventsPanel />
      </section> */}
    </UserLayout>
  );
};

export default MyComplaintsPage;
