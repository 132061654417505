import React from 'react';
import { useQuery } from 'react-query';

import { getMyComplaints } from '$utils/queryFunctions';

import Table from '$ui/table';
import Loader from '$ui/loader';
import Anchor from '$ui/anchor';
import NoData from '$ui/noData';

const ComplaintsTable = () => {
  const {
    data: complaints,
    isLoading,
    isError
  } = useQuery('my-complaints', getMyComplaints);

  if (isLoading) return <Loader />;

  if (isError || !complaints.length) return <NoData />;

  return (
    <Table
      className='phone:text-atmoic'
      headers={['S.NO', 'date', 'against', 'type', 'status', '']}>
      {complaints.map((c, i) => (
        <tr key={c.id}>
          <td>#{i + 1}</td>
          <td>{new Intl.DateTimeFormat().format(new Date(c.createdAt))}</td>
          <td>{c.opposition.personOfImportance}</td>
          <td>{c.issueType}</td>
          <td>{c.status}</td>
          <td>
            <Anchor
              size='small'
              href={`/me/complaints/${c.id}`}
              variant='outlined-colored'>
              Details &nbsp; &rarr;
            </Anchor>
          </td>
        </tr>
      ))}
    </Table>
  );
};

export default ComplaintsTable;
