import React from 'react';
import classNames from 'classnames';

const Loader = ({ variant, className }) => (
  <div className={`flex justify-center items-center ${className}`}>
    <div
      className={classNames(
        'animate-spin rounded-full',
        variant === 'small' ? 'h-6 w-6 border-2' : 'h-20 w-20 border-8'
      )}
      style={{ borderTopColor: 'rgba(31, 41, 55, .9)' }}></div>
  </div>
);

export default Loader;
